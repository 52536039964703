import React, { useContext, useEffect } from "react"
import BigImage from "../bigImage"
import { useIntl } from "gatsby-plugin-intl"
import Slider from "react-slick"
import { deviceContext } from "../layout"

const banners = [
  {
    image: "banner-one",
  },
  {
    image: "banner-two",
  },
]

const FullPageBanner = () => {
  const intl = useIntl()
  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  useEffect(() => {
    // @ts-ignore
    document.querySelector(".slick-dots").style.bottom = "25px"
  }, [])

  const device = useContext(deviceContext)

  return (
    <div style={{ height: "100vh" }}>
      <Slider {...settings}>
        {banners.map(banner => (
          <div className="banner" key={banner.image}>
            <BigImage
              src={`index/${banner.image}-${device}.jpg`}
              style={{
                height: "calc(100vh - 95px)",
              }}
            ></BigImage>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default FullPageBanner
