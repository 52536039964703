import React from "react"

import Layout from "../components/layout"
import {
  FullPageBanner,
  CustomizeSection,
  PhotoSection,
  ProductSection,
} from "../components/index"
import SEO from "../components/seo"
import "../style/index.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const IndexPage = props => {
  return (
    <Layout {...props}>
      <SEO />
      <div className="index-page">
        <FullPageBanner />
        <CustomizeSection />
        <PhotoSection />
        <ProductSection />
      </div>
    </Layout>
  )
}

export default IndexPage
