import React from "react"
import BigImage from "../bigImage"

const PhotoSection = () => {
  return (
    <div className="photo-section">
      <div className="top-right-triangle-section"></div>
      <div className="background">
        <BigImage src="index/background.jpg" style={{ height: "100%" }} />
      </div>
      <div
        className="bottom-left-triangle-section"
        style={{ bottom: "0" }}
      ></div>
    </div>
  )
}

export default PhotoSection
