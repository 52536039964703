import { useIntl, Link } from "gatsby-plugin-intl"
import React, { useEffect, useRef } from "react"
import Slider from "react-slick"
import products from "../../../products"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"

const MySwal = withReactContent(Swal)

const ProductsSection = () => {
  const intl = useIntl()
  const downloadLinkRef = useRef<HTMLAnchorElement>()

  const settings = {
    dots: true,
    infinite: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    autoplay: true,
  }

  const handleDownloadClick = async () => {
    const { value: email } = await MySwal.fire({
      title: intl.formatMessage({
        id: `index.productSection.download.title`,
      }),
      input: "email",
      inputLabel: intl.formatMessage({
        id: `index.productSection.download.inputLabel`,
      }),
      inputPlaceholder: intl.formatMessage({
        id: `index.productSection.download.inputPlaceholder`,
      }),
      showCloseButton: true,
    })
    if (email && downloadLinkRef.current) {
      downloadLinkRef.current.click()
      fetch("https://submit-form.com/luUnTZ2WYZhqth4eQs-7d", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      })
    }
  }

  return (
    <div className="products-section">
      <h3 className="title">
        {intl.formatMessage({ id: "index.productSection.title" })}
      </h3>
      <div className="title text-secondary">
        {intl.formatMessage({ id: "index.productSection.subtitle" })}
      </div>
      <div className="mt-3">
        <Slider {...settings} className="slider">
          {Object.keys(products).map(category => {
            return (
              <div className="product-image-container" key={category}>
                <Link to={`/products`}>
                  <img
                    src={require(`../../images/index/products/${category}.png`)}
                    className="product-image"
                  />
                  <h5 className="name">
                    {intl.formatMessage({
                      id: `products.${category}.name`,
                    })}
                  </h5>
                </Link>
              </div>
            )
          })}
        </Slider>
      </div>
      <h3
        onClick={handleDownloadClick}
        style={{ cursor: "pointer", marginTop: "80px" }}
      >
        {intl.formatMessage({
          id: `index.productSection.download.title`,
        })}
      </h3>
      <a
        ref={downloadLinkRef}
        href="https://drive.google.com/uc?export=download&id=19mCdeCbSm2gjnWq8qLt0djP93GJzcNxS"
      ></a>
    </div>
  )
}

export default ProductsSection
