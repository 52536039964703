const products = {
  "metal-forging": {
    imageLength: 7,
  },
  spinning: {
    imageLength: 9,
  },
  "die-casting": {
    imageLength: 10,
  },
  "lost-wax-casting": {
    imageLength: 11,
  },
  "stamping-press": {
    imageLength: 7,
  },
  "cnc-processing": {
    imageLength: 9,
  },
  special: {
    imageLength: 6,
  },
  "surface-treatment": {
    imageLength: 9,
    names: [
      'anodizing-process',
      'chrome-plating',
      'stain-chrome-plating',
      'pvd',
      'laser-engraving',
      'antique-finish',
      'antique-finish',
      'hairline-finish',
      'copper-plating',
    ]
  }
}

module.exports = products
