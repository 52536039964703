import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import TinyImage from "../tinyImage"

const CustomizeSection = () => {
  const intl = useIntl()

  return (
    <div className="customize-section-container">
      <div className="container-lg">
        <div className="row customize-section">
          <div className="image-container col-sm-6">
            <TinyImage src="index/customize.jpg"></TinyImage>
          </div>
          <div className="description col-sm-6">
            <div className="tem">
              <div className="title h4">
                {intl.formatMessage({ id: "index.customizeSection.title" })}
              </div>
              <div className="content">
                {intl.formatMessage({ id: "index.customizeSection.content" })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomizeSection
